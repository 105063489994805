<script>
import store from "../store";

export default {
  name: 'nav',
  data(){
    return{
      permissionsData:{},
      sidebarNav: [{
        _name: 'CSidebarNav',
        _children: []
      }]
    }
  },
  created (){
    console.log(process.env.NODE_ENV);
    
    this.permissionsData = store.state.permissions;
    var items = this.sidebarNav[0]._children;

    items.push(
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      }
    );
    
    if(this.permissionsData.view_users){
      items.push(
        {
          _name: 'CSidebarNavItem',
          name: 'Users',
          to: '/users',
          icon: 'cil-people'
        }
      );
    }

    //if(this.permissionsData.edit_site){
    items.push(
      {
        _name: 'CSidebarNavItem',
        name: 'Sites',
        to: '/sites',
        icon: 'cil-city'
      }
    );
    //}

    if(this.permissionsData.view_menus){
      items.push(
        {
          _name: 'CSidebarNavItem',
          name: 'Menus',
          to: '/menus',
          icon: 'cil-restaurant-menu'
        }
      );
    }

    items.push(
    {
      _name: 'CSidebarNavItem',
      name: 'Reports',
      to: '/reports',
      icon: 'cil-dollar-circle'
    }
    );

  },
  computed: {
    sidebarItems() {
      return this.sidebarNav
    }
  }
}
</script>
