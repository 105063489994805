<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="pr-2">{{ username }}</div>
        <div class="c-avatar">
          <img :src="profile_photo" class="c-avatar-img " />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem @click="$router.push('profile')">
      <CIcon name="cil-user" /> Profile
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { Auth } from "aws-amplify";
import store from "../store";
export default {
  name: "TheHeaderDropdownAccnt",
  props: {
    //username: String,
  },
  data() {
    return {
      username: store.state.currentUser.name,
      profile_photo: store.state.currentUser.photo,
    };
  },
  created() {
    this.username = store.state.currentUser.name;
    this.profile_photo = store.state.currentUser.photo;
  },
  methods: {
    async logout() {
      try {
        await Auth.signOut();
        this.$router.push("/login");
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  watch: {
  '$store.state.currentUser.name': function() {
    this.username = store.state.currentUser.name;
  },
  '$store.state.currentUser.photo': function() {
    this.profile_photo = store.state.currentUser.photo;
  }
}
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
