<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} Jogg, Inc.</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Created by</span>
      <a href="https://techpro.com">TechPro, Inc.</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
