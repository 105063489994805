<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand
      class="mx-auto d-lg-none"
      src="img/jogg-logo-white.svg"
      width="190"
      height="46"
      alt="Jogg Logo"
    />
    <CHeaderNav class="d-md-down-none mr-auto">{{ title }}</CHeaderNav>
    <CHeaderNav>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
  },
  data () {
    return {
      title: this.$route.name
    }
  },
  watch: {
  '$route' () {
    this.title = this.$route.name;
  }
}
}
</script>
